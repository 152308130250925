body {
    font-size: 15px;
    line-height: 1.5em;
    font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto Slab", "Roboto", sans-serif;
}

.heading-plain {
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 400;
}


/* Images */

img {
    max-width: 100%;
    height: auto;
}

.wp-caption {
    padding: 0 1em;
}

.wp-caption.alignright {
    padding-right: 0;
}

.wp-caption.alignrleft {
    padding-left: 0;
}

.wp-caption-text {
    margin-top: 0.5em 0 1em;
    font-size: 0.85em;
    color: rgba(0, 0, 0, 0.6);
}

p {
    font-weight: 400;
    margin-bottom: 1em;
}


/* Lists in normale contents */

.blog-text ul:not(.list-unstyled) {
    list-style-type: none;
    padding-left: 1.75em;
}

.blog-text ul:not(.list-unstyled) li {
    position: relative;
    padding-left: 1.3em;
}

.blog-text ul:not(.list-unstyled) li:before {
    content: "»";
    left: 0;
    top: 0;
    margin-top: 0.05em;
    line-height: 1em;
    font-size: 1.2em;
    position: absolute;
    color: #7ac045;
    font-weight: bold;
}


/* Links */

a:not(:hover) {
    color: #7ac045;
}


/* altes gruen: #7ac045 */


/* Images */

.alignright {
    margin-left: 1em;
    float: right;
}

.alignleft {
    margin-right: 1em;
    float: left;
}

.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}


/* Tables */

table:not(.table-nonstandard) {
    width: 100% !important;
    max-width: 100%;
    margin-bottom: 20px;
    border: none;
    color: #444;
    border: 1px solid #ddd;
}

table:not(.table-nonstandard) td {
    padding: 0.6em 0.85em;
}

table:not(.table-nonstandard) tbody td {
    border-bottom: 1px solid #ddd;
}

table:not(.table-nonstandard) thead tr {
    font-weight: 500;
    padding: 0.5em;
    border-bottom: 1px solid #ddd;
    background: #ddd;
    color: #000;
}


/* Buttons */

button:focus,
button:active,
.btn:focus,
.btn:active {
    box-shadow: none !important;
    outline: none !important;
}

.btn-kok {
    background: #7ac045;
    color: #fff !important;
    font-weight: 500;
    border-bottom: 0.25em solid #669f3a;
    transition: all 0.12s ease-in-out;
}

.btn-kok:hover {
    background: #67b12f;
    border-bottom-color: #4a7926;
}

.btn-white {
    background: rgba(255, 255, 255, 0.6);
    color: #333 !important;
    border-bottom: 0.25em solid rgba(0, 0, 0, 0.3);
    font-weight: 500;
    transition: all 0.12s ease-in-out;
}

.btn-white:hover {
    background: rgba(255, 255, 255, 1);
    border-bottom: 0.25em solid rgba(0, 0, 0, 0.45);
}

.btn-black {
    background: rgba(0, 0, 0, 0.45);
    color: #fff !important;
    border-bottom: 0.25em solid rgba(0, 0, 0, 0.3);
    font-weight: 500;
    transition: all 0.12s ease-in-out;
}

.btn-black:hover {
    background: rgba(0, 0, 0, 0.6);
    border-bottom: 0.25em solid rgba(0, 0, 0, 1);
}

.btn-big {
    padding: 0.75em;
    /*margin-top: 0.85em;*/
    width: 100%;
    text-transform: uppercase;
}

.button-companion {
    margin-top: 0.5em;
    display: block;
    font-size: 0.8em;
    /*letter-spacing: 0.1em;*/
    text-transform: uppercase;
    text-align: center;
    /*color: #fff;*/
    text-decoration: none !important;
    opacity: 0.87;
    transition: all 0.12s ease-in-out;
}

a.button-companion:hover {
    opacity: 1;
    /*color: #fff;*/
}

.button-companion span {
    font-weight: 500;
}

.btn-grey {
    background: #aaa;
    border-bottom-color: #999;
}

.btn-grey:hover {
    background: #999;
    border-bottom-color: #888;
}

.btn-grey-o {
    color: #888 !important;
    font-weight: 400;
    background: none;
    border: 2px solid #bbb;
}

.btn-grey-o:hover {
    color: #fff !important;
    background: #999;
    border: 2px solid #999;
}

.btn-purple {
    background: #754A82;
    border-bottom-color: #3C2642;
}

.btn-purple:hover {
    background: #5E3B68;
    border-bottom-color: #000;
}


/* Inputs */

.form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: #7ac045;
}

.input-group-btn .btn-kok {
    border: 1px solid #7ac045;
    border-top: none;
    border-bottom: 2px solid #467A30;
    position: relative;
    top: 0;
}

.input-group-btn .btn-kok:active {
    box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.5) !important;
}


/* Page & Section Titles */

.page-title,
.section-title {
    font-family: "Roboto", sans-serif;
    font-size: 1.6em;
    margin-bottom: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.85em;
    border-bottom: 1px solid #ddd;
    position: relative;
    line-height: 1.3em;
}

.page-title,
.section-title,
.page-title a:not(.styled-link),
.section-title a:not(.styled-link) {
    color: #222;
    text-decoration: none;
}


/*.page-title:after, .section-title:after {
		content: "";
		display: block;
		height: 3px;
		width: 100px;
		background: #7ac045;
		position: absolute;
		left: 0;
		bottom: -3px;
	}*/

.page-title .subtitle,
.section-title .subtitle {
    display: block;
    font-weight: 400;
    font-size: 0.9em;
    color: #777;
}

.page-title-alt {
    font-size: 2em;
    color: #aaa;
    font-weight: normal;
    font-family: "Roboto", sans-serif;
}


/* Purple Section Title */

.page-title.purple:after,
.section-title.purple:after {
    background: #754A82;
}


/* Section Title ohne Trennlinie */

.page-title.borderless,
.section-title.borderless {
    border: none;
}

.page-title.borderless:after,
.section-title.borderless:after {
    display: none;
}

.center-line {
    position: relative;
    text-align: center;
}

.center-line .white-box {
    background: #fff;
    position: relative;
    z-index: 1;
    padding: 0 1em;
    display: inline-block;
}

.center-line:after {
    z-index: 0;
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    border: 1px solid #eee;
}

.section-link {
    float: right;
    color: #999 !important;
    padding-top: 0.6em;
    text-decoration: none !important;
}

.section-link:hover {
    color: #555 !important;
}

.coloured-container {
    background: #eee;
}

.title-small {
    font-size: 1em;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    line-height: 1.3em;
}


/* Autocomplete */

div.awesomplete {
    display: block !important;
}

.awesomplete ul {
    color: #555 !important;
}

.awesomeplete-with-button {
    position: relative;
}

.awesomplete-button {
    width: auto !important;
    box-sizing: border-box;
    position: absolute;
    right: 1em;
    z-index: 2;
}

.awesomplete-button .btn-kok {
    border-radius: 0;
    border-top-right-radius: 0.3em !important;
    border-bottom-right-radius: 0.3em !important;
}


/* Box */

.box {
    margin-bottom: 2em;
    border: 2px solid #999;
}

.box-title {
    font-size: 1em;
    margin: 0;
    font-family: "Roboto", sans-serif;
    background: #999;
    color: #fff;
    padding: 0.8em;
    text-align: center;
    text-transform: uppercase;
}

.box-content {
    padding: 0.8em;
}


/* List */

.box-list {}

.box-list li {
    padding: 0.75em;
    border-bottom: 1px solid #e5e5e5;
}


/* Download List */

.download-button-small {
    font-size: 0.85em;
    padding: 0.4em 0.6em;
    text-transform: uppercase;
}

.box-download-list {
    margin: 0;
    font-size: 0.9em;
}

.box-download-list li {
    padding: 0.75em 0.9em;
    overflow: hidden;
    border-bottom: 1px solid #ddd;
}

.box-download-list li:last-child {
    border: none;
}

.box-download-list a {
    color: #777;
}

.box-download-list .download-title {
    display: block;
    color: #777;
    width: auto;
    overflow: hidden;
    word-wrap: break-word;
    transition: all 0.12s ease-in-out;
}

.box-download-list a:hover .download-title {
    color: #555;
}

.box-download-list .download-icon {
    width: 30px;
    font-size: 1.5em;
    float: left;
    display: inline-block;
    color: #999;
    transition: all 0.12s ease-in-out;
}

.box-download-list a:hover .download-icon {
    color: #777;
}

.box-download-list .download-button {
    float: right;
    display: block;
}


/* Blog Text */

.blog-text {
    line-height: 1.55em;
}

.blog-text h2,
.blog-text h3 {
    font-size: 1.4em;
    margin: 1.85em 0 1em;
    font-family: "Roboto", sans-serif;
}

.blog-text h2:first-child,
.blog-text h3:first-child {
    margin-top: 0;
}

.blog-text h3 {
    font-size: 1.25em;
}

.blog-box {
    margin: 2em 0 0;
    padding: 2em;
    font-size: 1.2em;
    overflow: hidden;
    background: #eee;
    color: #737373;
    position: relative;
    border-radius: 0.4em;
    text-align: center;
}

.blog-box a {
    color: #555;
}

.blog-box.with-icon {
    padding-left: 4em;
    position: relative;
}

.blog-box.with-icon .icon {
    font-size: 1.5em;
    position: absolute;
    left: 1em;
    top: 50%;
    margin-top: -0.65em;
}

.blog-box .pic {
    width: 200px;
    float: left;
    display: table;
    height: 100%;
    margin-right: 2em;
}

.blog-box .pic-inner {
    display: table-cell;
    vertical-align: middle;
}

.blog-box-content {
    width: auto;
    overflow: hidden;
}

.blog-box h3 {
    color: #555;
    font-size: 1.1em;
    margin: 0 0 1em;
}

.blog-box p:last-child {
    margin: 0;
}


/* - Comments - */

#comments {
    margin-bottom: 6em;
    padding-bottom: 4em;
}

.comment-list {
    padding: 0;
}

.comment {
    padding-left: 2em;
}

.comment:first-child {
    padding: 0;
}

.comment-body-inner {
    border-bottom: 1px solid #ddd;
    padding-top: 1em;
    padding-bottom: 1em;
    color: #555;
}

.comment-author {
    font-size: 1.1em;
    font-style: normal;
    color: #000;
}

.comment-meta {
    margin-bottom: 1em;
    color: #999;
    font-size: 0.85em;
}

.comment-meta a {
    color: #999;
}

.comment-reply-link {
    display: inline-block;
    border: 2px solid #ccc;
    padding: 0.6em 0.8em;
    line-height: 1em;
    ;
    color: #999 !important;
    border-radius: 0.3em;
    text-decoration: none !important;
    font-size: 0.85em;
}

.comment-reply-link:hover {
    background: #ccc;
}

#respond {
    /*border-top: 2px solid #ddd;*/
    margin-top: 2em;
    border: 2px solid #ddd;
    padding: 1em 2em;
}

.comment #respond {
    margin-top: 0;
    padding-top: 0;
    margin: 1em;
}

.comment-input-section {
    margin-bottom: 1em;
    max-width: 500px;
}

#reply-title {
    font-size: 1.4em;
    font-family: "Roboto", sans-serif;
}

.comment-notes {
    color: #999;
    font-size: 1em;
    font-weight: 300;
    margin-bottom: 2em;
}

.form-allowed-tags {
    font-size: 0.85em;
    opacity: 0.7;
    margin: 1.5em 0 2.5em;
}


/* - Tabs - */

.nav-tabs {
    border: none;
    border-bottom: 2px solid #888;
    text-transform: uppercase;
}

.nav-tabs li,
.nav-tabs li.active {
    margin: 0;
    border: none !important;
    font-weight: 500;
}

.nav-tabs li a,
.nav-tabs li.active a {
    border: none !important;
    outline: none !important;
    border-radius: 0;
    color: #888;
    transition: all 0.12s ease-in-out;
}

.nav-tabs li a:hover {
    color: #fff;
    background: #aaa;
}

.nav-tabs li.active a,
.nav-tabs li.active a:hover,
.nav-tabs li.active a:active,
.nav-tabs li.active a:focus {
    background: #888;
    color: #fff;
}


/* Image Gallery */

.gallery {
    border-radius: 5px;
    background: #eee;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
}

.gallery li {
    float: left;
    width: 20%;
    padding-bottom: 20%;
    position: relative;
    overflow: hidden;
}

.gallery li .gallery-element-inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.gallery li .gallery-element-inner-inner {
    display: table;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    table-layout: fixed;
}

.gallery li a {
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 0.5em;
    text-align: center;
    vertical-align: middle;
    border-radius: 3px;
    overflow: hidden;
    border: 2px solid #ddd;
    transition: all 0.12s ease-in-out;
    position: relative;
    background-color: #fff;
    table-layout: fixed;
}

.gallery li a:after {
    content: "\f065";
    font-family: "FontAwesome";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 50%;
    font-size: 30px;
    color: #fff;
    margin-top: -16px;
    line-height: 30px;
    opacity: 0;
    transition: all 0.12s ease-in-out;
}

.gallery li a:before {
    position: absolute;
    content: "";
    width: 50%;
    height: 50%;
    left: 25%;
    top: 25%;
    opacity: 0;
    background: #7ac045;
    border-radius: 99999em;
    /*border: 2px solid rgba(255,255,255,0.5);*/
    transition: all 0.12s ease-in-out;
}

.gallery li a:hover {
    border-color: #7ac045;
}

.gallery li a:hover:before {
    opacity: 0.87;
}

.gallery li a:hover:after {
    opacity: 1;
}

.gallery li img {
    max-width: 100%;
    max-height: 100%;
}


/* Google Maps */

.acf-map {
    width: 100%;
    height: 580px;
    border-radius: 0.5em;
    overflow: hidden;
    margin: 0;
}


/* --- Section: Header --- */


/* - Top Bar - */

#topbar {
    background: #f2f2f2;
    font-size: 0.85em;
    color: #777;
    text-align: center;
    display: none;
}

#topbar p {
    margin: 0;
    padding: 1em;
    display: inline-block;
    border-left: 1px solid #e2e2e2;
}

#topbar a {
    margin: -1em;
    padding: 1em;
    display: block;
    color: #777;
    text-decoration: none !important;
}

#topbar a:hover {
    color: #444;
}

#topbar p:first-child {
    border: none;
}


/* - Header - */

#header {
    // min-height: 245px;
    transition: box-shadow 0.16s ease;
}

#logo {
    padding-top: 20px;
    transition: none;
}

#header.sticky {
    font-size: 1em;
    position: fixed;
    width: 100%;
    background: rgba(255, 255, 255, 0.99);
    top: 0;
    z-index: 1001;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}


/* move the header down a bit if the admin bar is visible */

.admin-bar #header.sticky {
    top: 32px;
}

.search-wrapper-wrapper {
    position: relative;
}

#header-search {
    width: 30px;
    padding-top: 24px;
    margin-left: 8px;
    float: right;
    text-align: right;
    position: relative;
}

.search-button {
    display: inline-block;
    margin: 18px 0 0;
    padding: 0;
    border: none;
    background: none;
    color: #7ac045;
    font-size: 22px;
    text-align: right;
    transition: all 0.12s ease-in-out;
}

.search-button:hover {
    color: #366401;
}

.search-wrapper {
    max-height: 0;
    position: absolute;
    top: 14px;
    bottom: 0;
    left: 0;
    padding-top: 1.5em;
    padding-left: 1em;
    overflow: hidden;
    right: 50px;
    opacity: 0;
    z-index: 4;
    background: #fff;
    transition: max-height 0.18s ease-in-out;
}

.search-wrapper.shown {
    max-height: 150px;
    opacity: 1;
    z-index: 7;
}

.search-heading {
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.6em;
    border-bottom: 2px solid #7ac045;
}


/* --- Section: Sidebar --- */

#sidebar {
    margin-top: -2em;
}

.page-2col #sidebar {
    margin-top: 0;
}

#sidebar-inner {
    padding-left: 2em;
}

.sidebar-box {
    margin-bottom: 0 0 60px;
    overflow: hidden;
    border: none;
}

.sidebar-box .box-title {
    padding-left: 0;
    background: none;
    color: #222;
    text-align: left;
}

.sidebar-box .box-contents {
    font-size: 0.9em;
}

.sidebar-box .box-contents.text {
    padding: 1em 0;
}


/* --- Section: Footer --- */

footer {
    margin-top: 3em;
    background: #2B3626;
    padding: 2.5em 0;
    font-size: 0.9em;
    color: #bbb;
}

footer a:not(:hover) {
    color: #bbb;
    transition: all 0.12s ease-in-out;
}

footer p {
    margin: 0.5em 0;
}

footer .small {
    font-size: 1em;
    color: #777;
}

footer .small a {
    color: #777;
}

footer a:hover {
    color: #ddd;
    text-decoration: none;
}

footer ul {
    padding-top: 1.25em;
}

footer ul a {
    color: #777;
}

footer ul a:hover {
    color: #ddd;
}

footer img {
    margin-top: 0.8em;
    max-width: 155px;
}

.sticky-footer footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}


/* -- Page: Front Page -- */

.front-page-main-container {
    margin-top: 2em;
}


/* --- Modul: Page Headers --- */


/* - Static Page Header - */

.entry-header {
    /*background-image: url("img/background.jpg");*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #6DA234;
    color: #fff;
    margin-bottom: 2.5em;
}

.entry-header .container {
    padding-top: 4em;
    padding-bottom: 4.5em;
    position: relative;
}

.entry-header .title {
    font-weight: bold;
    font-size: 2em;
    font-family: "Roboto Slab", sans-serif;
}

.entry-header .title a {
    color: #fff;
}

.entry-header .page-subtitle {
    opacity: 0.7;
    font-size: 1.3em;
}

.entry-header .back-to {
    padding: 0.75em;
    color: #fff;
    background: #4D8E08;
}

.entry-header .back-to a {
    opacity: 0.77;
    color: #fff;
    text-decoration: none;
    transition: all 0.12s ease-in-out;
}

.entry-header .back-to a:hover {
    opacity: 0.9;
}

.entry-header .back-to i {
    margin-right: 0.5em;
}


/* Entry Header with Picture */

.entry-header.entry-header-pic {
    background-color: transparent;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.entry-header-pic .title-wrapper {
    /*background: rgba(105,161,26,0.5);*/
    position: relative;
}

.entry-header-pic .title-wrapper:after {
    content: "";
    width: 100%;
    left: 0;
    position: absolute;
    bottom: 0;
    height: 1em;
    background: rgba(0, 0, 0, 0.1);
}

.entry-header-pic .post-date {
    opacity: 0.8;
    font-weight: 300;
}


/* Lila Entry Header */

.entry-header-pic.purple .title-wrapper {
    background: rgba(136, 39, 168, 0.4);
}

.entry-header .container {
    padding-top: 4em;
    padding-bottom: 5em;
    position: relative;
}


/* -- Share Buttons -- */

.share-buttons {
    position: absolute;
    right: 1em;
    bottom: 2em;
}

.share-buttons-inline {
    text-align: right;
    margin: 0 0 1em;
    bottom: 0;
    right: 0;
    position: relative;
    z-index: 20;
    display: inline-block;
}

.share-buttons-no-position-change {
    bottom: 0.75em;
    right: 0;
}


/* --- Modul: Main Menu --- */

#kok-mainmenu {
    transition: margin 0.16s ease-in-out;
    padding: 2em 0 0;
    min-height: 130px;
    position: relative;
    z-index: 6;
    width: auto;
    overflow: hidden;
}

.nav-title {
    display: none;
}

#header.sticky #kok-mainmenu {
    padding-bottom: 0.5em;
}

.jvs-menu {
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: relative;
    border-bottom: 2px solid #7ac045;
    font-size: 0.94em;
}

.jvs-menu ul,
.jvs-menu li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

#kok-mainmenu a {
    transition: all 0.12s ease-in-out;
}


/* - Top Level Pages - */

.jvs-menu > li {
    float: left;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    box-sizing: border-box;
}

.jvs-menu > li a {
    display: block;
    padding: 0.7em 0.85em;
    color: #333;
    text-decoration: none;
}

.jvs-menu > li a:hover {
    background: #eee;
}

.jvs-menu > li.has-children a:after {
    content: "\f107";
    font-family: "FontAwesome";
    margin-left: 0.5em;
    line-height: 0.5em;
}

.jvs-menu:not(.showing-other-subpages) > li.current_page_item a,
.jvs-menu:not(.showing-other-subpages) > li.current_page_ancestor a,
.jvs-menu.showing-other-subpages .surrogate_current_page_item a {
    background: #7ac045;
    color: #fff !important;
}

.jvs-menu.showing-other-subpages > li.current_page_item a:not(:hover) {
    background: #eee;
}

.jvs-menu > li .subpages {
    display: none;
}


/* - Subpage Section - */

.subpages-parent-title {
    display: none;
}

.subpages-wrapper {
    display: block;
    clear: both;
}

.subpages-wrapper-contents {
    width: 100%;
    overflow: hidden;
    display: none;
}

.subpages-wrapper-contents ul.subpages {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.subpages-wrapper-contents .subpages li {
    float: left;
    display: block;
}

.subpages-wrapper-contents .subpages li a {
    display: block;
    padding: 0.7em 0.85em;
    font-size: 0.92em;
    color: #333;
    text-decoration: none;
}

.subpages-wrapper-contents .subpages li a:hover,
.subpages-wrapper-contents .subpages li.current_page_item a {
    color: #7ac045;
}

.subpages-wrapper-contents .subpages li.current_page_item a {
    font-weight: 500;
}


/* --- Modul: Slider --- */

.tp-caption a {
    color: #fff !important;
}

.slider-container {
    position: relative;
    margin-bottom: 3em;
}

.slider-bottom-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 16px;
    background: rgba(255, 255, 255, 0.9);
    background: rgba(0, 0, 0, 0.4);
    z-index: 988;
    color: #444 !important;
    color: #fff !important;
}

.slider-bottom-bar .title {
    font-size: 1.5em;
}

.slider-bottom-bar .subtitle {
    font-size: 1.1em;
    opacity: 0.75;
}

.slider-bottom-bar a.button-companion {
    color: #fff;
    opacity: 0.7;
}

.slider-bottom-bar a.button-companion:hover {
    opacity: 1;
}


/* --- Modul: Teaser Boxen --- */

.teasers-container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.teaser {
    overflow: hidden;
}


/* - Grosse Teaser - */

.teaser.big {
    background: #f1fae8;
    border-radius: 0.5em;
    padding: 0.75em 1.25em;
    text-align: center;
    margin-bottom: 1em;
    transition: background 0.18s ease-in-out;
}

.teaser.big:hover {
    background: #def6ca;
}

.teaser.big .teaser-title {
    font-family: "Roboto Slab", "Roboto", sans-serif;
    font-size: 1.6em;
    margin-bottom: 0.35em;
    color: #7ac045 !important;
}

.teaser.big .teaser-text {
    color: rgba(0, 0, 0, 0.5);
}

.teaser.big .teaser-pic {
    margin-top: 1.25em;
    opacity: 0.9;
}

.teaser.big .teaser-pic img {
    max-height: 130px;
}


/* - Kleine Teaser (optional mit Icon) - */

.teaser-small {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    position: relative;
}

.teaser-small .teaser-icon {
    float: left;
    width: 45px;
    font-size: 28px;
    color: #7ac045;
}

.teaser-small .teaser-contents {
    overflow: hidden;
    padding: 0 1em 1em;
}

.teaser-small .teaser-contents p {
    margin: 0;
}

.teaser-small .teaser-contents > a:not(.btn) {
    margin: -1em;
    display: block;
    padding: 1em;
    text-decoration: none;
}

.teaser-small .teaser-title {
    color: #000 !important;
    margin-top: 0;
    font-size: 1.3em;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
}

.teaser-small .teaser-text {
    color: #777;
}

.teaser-small .teaser-link {
    display: block;
    padding-top: 1em;
}

.teaser-small.with-border {
    text-align: center;
    transition: all 0.12s ease-in-out;
}

.teaser-small.with-border a {
    padding: 1em;
    height: 100%;
    display: block;
    position: relative;
    text-decoration: none !important;
    border: 2px solid #7ac045;
    transition: all 0.12s ease-in-out;
    padding-right: 5.2em;
    border-radius: 0.3em;
    opacity: 0.8;
}

.teaser-small.with-border a:hover,
.teaser-small.with-border a:active {
    border-color: #67B334;
    opacity: 1;
}

.teaser-small.with-border.with-icon {
    text-align: left;
}

.teaser-small.with-border.with-icon .teaser-image-wrapper {
    width: 160px;
    float: left;
    padding: 0.75em;
    height: 100%;
    margin-left: -1em;
    margin-right: 0.75em;
    position: relative;
}

.teaser-small.with-border.with-icon .teaser-icon-wrapper {
    width: 80px;
}

.teaser-small.with-border.with-icon .teaser-icon {
    text-align: center;
    font-size: 42px;
    float: none;
    width: auto;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -21px;
}

.teaser-small.with-border.with-icon .teaser-image {
    padding: 0.5em;
}

.teaser-small.with-border .teaser-link {
    background: #7ac045;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 60px;
    transition: all 0.12s ease-in-out;
}

.teaser-small.with-border a:hover .teaser-link,
.teaser-small.with-border a:active .teaser-link {
    background: #67B334;
}

.teaser-small.with-border .teaser-link i {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -0.8em;
    margin-top: -0.8em;
    padding: 0.5em 0.57em 0.45em 0.7em;
    border-radius: 9999em;
    background: #fff;
    color: #7ac045;
    transition: all 0.12s ease-in-out;
}

.teaser-small.with-border a:hover .teaser-link i {
    color: #67B334;
}

.teaser-small.with-border .teaser-content {
    width: auto;
    overflow: hidden;
}


/* - Blog Teaser - */

.teaser.blog {}

.teaser-blog-inner {
    border: 1px solid #ccc;
    border-radius: 5px;
}

.teaser-blog-inner > * {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.teaser.blog .teaser-date {
    display: none;
    background: #7ac045;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.6em 1em;
    text-align: center;
    opacity: 0.95;
    line-height: 1.2em;
}

.teaser.blog .teaser-date .day {
    font-size: 1.2em;
    font-weight: 600;
}

.teaser.blog .teaser-date .month {
    display: block;
    font-size: 1em;
    opacity: 0.8;
    font-weight: 500;
}

.teaser.blog .teaser-date .year {
    display: block;
    font-size: 0.75em;
    opacity: 0.6;
}

.teaser.blog .teaser-contents {
    padding-top: 1em;
}

.teaser.blog .teaser-title {
    margin: 0;
    font-size: 1.1em;
    text-transform: none;
    line-height: 1.3em;
    margin-bottom: 0.5em;
}

.teaser.blog .teaser-text {
    font-size: 0.9em;
}

.teaser-pic-wrapper {
    overflow: hidden;
    display: block;
    margin: 0;
    transition: background 0.2s linear;
}

.teaser.blog:hover .teaser-pic-wrapper {
    background: #89CD41;
}

.teaser.blog .teaser-pic {
    margin: 0;
    width: 100%;
    height: 240px;
    opacity: 0.8;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #eee;
}

.teaser.blog .teaser-pic.no-pic {
    background-size: 60%;
    opacity: 0.9;
    background-image: url("img/kok-logo-transparent.png");
}


/* - Sidebar Teaser - */

.sidebar-teaser {
    font-size: 0.9em;
    line-height: 1.5em;
    background: none !important;
}

.sidebar-teaser.big {
    margin-bottom: 5em;
}

.sidebar-teaser-big-icon {
    font-size: 120px;
    margin: 35px 0 20px !important;
    line-height: 0;
}

.sidebar-teaser {
    border-radius: 0.3em;
    display: block;
    padding: 1.5em;
    text-decoration: none;
    border: 2px solid #7ac045;
    transition: all 0.12s ease-in-out;
    overflow: hidden;
}

.sidebar-teaser > a:hover {
    opacity: 1;
}

.sidebar-teaser .teaser-title {
    font-size: 1.15em;
    font-family: "Roboto", sans-serif !important;
}

.sidebar-teaser.big .teaser-title {
    font-size: 2em;
}

.sidebar-teaser .teaser-contents {
    padding: 0 !important;
}

.sidebar-teaser .teaser-title,
.sidebar-teaser .teaser-icon,
.sidebar-teaser .teaser-text {
    transition: all 0.12s ease-in-out;
}

.sidebar-teaser .teaser-title,
.sidebar-teaser .teaser-title a {
    color: #333 !important;
}

.sidebar-teaser .teaser-icon,
.sidebar-teaser .teaser-icon a {
    color: #7ac045 !important;
}

.sidebar-teaser .teaser-text,
.sidebar-teaser .teaser-text a {
    color: #777 !important;
}


/* --- Modul: Bildliste --- */

.pic-list-wrapper.with-heading {
    border: 2px solid #7ac045;
}

.pic-list-heading {
    text-align: center;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 1.1em;
    padding: 0.75em;
    color: #fff;
    background: #7ac045;
}

.pic-list li {
    overflow: hidden;
    padding-top: 1.5em;
    padding-bottom: 1em;
    border-bottom: 2px solid #ddd;
}

.with-heading .pic-list li {
    border-color: #7ac045;
}

.pic-list li:last-child {
    border: none;
}

.pic-list .pic {
    text-align: center;
    padding: 0 2.5em;
}

.pic-list .pic-text {}

.pic-list .pic-list-item-title {
    font-size: 1.3em;
    margin: 0 0 1em;
    font-weight: 500;
}


/* --- Modul: Datei-Liste --- */

.download-list {}

.download-list .download-list-icon {
    font-size: 5em;
    margin-bottom: 20px;
}


/* --- Modul: Download --- */

.download-container {
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 2em 0;
    overflow: hidden;
    color: #7ac045;
}

.download-container .download-title {
    font-size: 1.4em;
    margin-top: 0.8em;
    font-family: "Roboto Slab", "Roboto", sans-serif;
}

.download-container .download-title a {
    color: #7ac045;
}

.download-container .download-icon {
    font-size: 70px;
    text-align: center;
}

.download-container .download-icon i {
    display: block;
    margin: 0;
}

.page-2col .download-container {
    border-radius: 0.5em;
}


/* --- Modul: Kalender --- */


/* - Event Modal - */

@media (min-width: 768px) {
    #event-modal .modal-dialog {
        width: 100%;
        max-width: 770px;
    }
}

#event-modal p {
    margin: 0;
}

#event-modal .modal-header {
    position: relative;
}

#event-modal .close {
    right: 0.85em;
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    font-size: 2em;
    z-index: 7;
}

#event-modal .modal-body {
    padding: 0;
}

#event-modal .modal-title {
    text-align: center;
    position: relative;
}

#event-modal .modal-title span {
    display: block;
    margin: 0;
    padding: 0;
}

#event-modal span.event-type {
    position: absolute;
    top: 50%;
    padding: 0.4em 0.65em;
    font-size: 0.75em;
    border-radius: 0.2em;
    margin-top: -1em;
    background: #77B043;
    color: #fff;
    opacity: 0.8;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
}

#event-modal .event-title {
    font-size: 1.25em;
}

#event-modal .event-date {
    font-size: 0.95em;
    font-weight: normal;
    color: #999;
    display: block;
    font-family: "Roboto", sans-serif;
}

#event-modal .event-info-wrapper {
    padding: 1.5em;
}

.event-popup-info-box {
    clear: both;
    overflow: hidden;
    position: relative;
    padding-left: 68px;
    margin: 1em 0 2.5em;
    color: #666;
}

.event-popup-info-box:before {
    font-family: "FontAwesome";
    left: 0;
    top: 50%;
    margin-top: -0.5em;
    position: absolute;
    font-size: 26px;
    line-height: 1.1em;
    color: #aaa;
    width: 2em;
    text-align: center;
}

.event-popup-info-box.date:before {
    content: "\f073";
}

.event-popup-info-box.location:before {
    content: "\f041";
}

.event-popup-info-box.veranstalter:before {
    content: "\f007";
}

.event-popup-info-box.date {
    color: #999;
}

.event-popup-info-box.date strong {
    color: #666;
    font-weight: normal;
}

#event-modal .google-maps-embed {
    width: 100%;
    height: 300px;
    border-radius: 0.25em;
    overflow: hidden;
    background: #eee;
    position: relative;
}

#event-modal .google-maps-embed:after {
    content: "Karte wird geladen…";
    position: absolute;
    top: 50%;
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: -0.5em;
    z-index: 0;
}

#event-modal .acf-map {
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;
}

#event-modal .acf-map > .gm-style {
    z-index: 3 !important;
}

#event-modal .event-description {
    padding: 1.5em;
    border-top: 1px solid #ddd;
}

#event-modal .event-description p {
    margin: 0 0 1.25em;
}


/* - Full Calendar Theme - */

.fc-toolbar {
    margin: 0;
    color: #fff;
    background: #B51420;
    border-top-left-radius: 0.7em;
    border-top-right-radius: 0.7em;
    padding: 1.5em 1em;
}

.fc-day-header {
    padding: 0.75em 0 !important;
    background: #eee !important;
    border: none;
}

.fc-view-container {
    border: 1px solid #ddd;
    border-right-width: 2px;
    border-top: none;
}

.fc-event {
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.12s ease-in-out;
}

.fc-event:hover {
    opacity: 1;
}

a.fc-event {
    color: #fff;
}

.fc-button {
    background: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
    color: #fff;
    opacity: 0.7;
}

.fc-button:hover {
    opacity: 1;
}

.fc-button:active {
    position: relative;
}

.fc-prev-button:active {
    left: -2px;
}

.fc-next-button:active {
    left: 2px;
}


/* - Additional Calender Styles - */

.calendar-info {
    margin-top: 1em;
    color: #777;
    font-size: 0.85em;
}

.calender-info-text {
    margin-top: 0.6em;
    color: #999;
}

.calendar-category-legend {
    text-align: right;
    font-size: 1.1em;
}

.calendar-category-legend ul {
    display: inline-block;
}

.calendar-category-legend .list-title {
    display: inline-block;
    margin-right: 0.75em;
}

.calendar-category-legend li {
    margin-left: 0.5em;
    padding: 0.4em 0.7em;
    background: #888;
    color: #fff;
    border-radius: 0.15em;
}

.calendar-category-legend li a {
    display: block;
    margin: -0.4em -0.7em;
    padding: 0.4em 0.7em;
    color: #fff;
}


/* - Event List - */

.event-box {
    margin-bottom: 1em;
}

.event-box-inner {
    overflow: hidden;
}

.event-box-inner p {
    margin: 0;
}

.event-box a {
    color: inherit;
    text-decoration: none !important;
}

.event-box .event-title {
    margin: 0 0 0.5em;
    font-size: 1.2em;
    font-family: "Roboto", sans-serif;
}

.event-box .event-date-box {
    width: 120px;
    float: left;
    border: 2px solid #6E98B6;
}

.event-box .event-date-box a {
    height: 100%;
    box-sizing: border-box;
    display: block;
}

.event-box .event-category {
    font-size: 0.85em;
    background: #2D749E;
    margin: -2px -2px 0;
    color: #fff;
    font-family: "Roboto", sans-serif;
    padding: 0.5em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
}

.event-box .event-date {
    font-family: "Roboto Slab", sans-serif;
    text-align: center;
    padding: 1em;
    overflow: hidden;
    box-sizing: border-box;
}

.event-box .event-date span {
    display: block;
    margin: 0;
    line-height: 1.2em;
}

.event-box .event-date .day {
    font-size: 2.3em;
}

.event-box .event-date .year,
.event-box .event-date .month {
    font-size: 1.2em;
    opacity: 0.7;
    font-family: "Roboto", sans-serif;
}

.event-box .event-date .multiple-events {
    color: #888;
    font-family: "Roboto", sans-serif;
}

.event-box .event-mobile-date {
    display: none;
}


/* Fortbildungsreihen */

.dates-list {
    margin: 1em -1.5em -1.5em;
    padding: 0.5em 1.5em;
    border-top: 2px solid #eee;
}

.dates-list li {
    font-size: 0.9em;
    margin-right: 1.5em;
    border-radius: 0.2em;
    color: #888;
}

.dates-list li .bis {
    font-size: 0.9em;
    color: #999;
}

.event-box .event-description {
    width: auto;
    overflow: hidden;
    border: 2px solid #ccc;
    border-left-width: 0;
    color: #666;
    transition: all 0.12s ease-in-out;
}

.event-box:hover .event-description {
    border-color: #aaa;
}

.event-box .event-title {
    color: #333;
}

.event-box .event-description a {
    height: 100%;
    padding: 1.5em;
    display: block;
}

.event-box .event-link {
    float: right;
    width: 60px;
    background: #ccc;
    position: relative;
    text-align: center;
    margin-left: -2px;
    transition: all 0.12s ease-in-out;
}

.event-box .event-link i {
    top: 50%;
    left: 50%;
    margin-left: -0.85em;
    margin-top: -0.85em;
    position: absolute;
    color: #aaa;
    background: #fff;
    border-radius: 99em;
    padding: 0.48em 0.65em 0.38em;
    padding-right: 0.5em;
    line-height: 1em;
    transition: all 0.12s ease-in-out;
}

.event-box:hover .event-link {
    background: #aaa;
}

.event-box:hover .event-link i {
    color: #777;
}


/* -- Event Single View -- */

.eventseries-title-date {
    color: #888;
    font-size: 0.8em;
    font-weight: normal;
    display: block;
    margin-top: 0.3em;
}

.series-part-title {
    display: block;
    font-weight: normal;
    color: #999;
    font-size: 0.8em;
}

.event-description {}

.event-description-title {
    font-size: 1.2em;
    margin-bottom: 1.5em;
    color: #888;
    font-family: "Roboto", sans-serif;
}

.event-description-content {
    padding-bottom: 1em;
    margin-bottom: 3em;
    border-bottom: 1px solid #ddd;
}

.event-description-content:last-child {
    margin: 0;
    border: none;
}

.eventseries-description {}

.eventseries-description.shortened {
    max-height: 250px;
    overflow: hidden;
    position: relative;
}

.eventseries-description.shortened:after {
    position: absolute;
    background: linear-gradient(transparent, white);
    content: "";
    display: block;
    height: 160px;
    width: 100%;
    left: 0;
    bottom: 0;
}

#eventseries-description-read-more {
    border-top: 1px solid #ddd;
    text-align: center;
    margin-top: 1.5em;
}

.read-more-button {
    margin-top: -1.75em;
    font-size: 0.9em;
    position: relative;
    z-index: 5;
    font-weight: normal;
}

.event-sponsors {
    margin: 2em 0;
    padding: 1em;
}

.event-sponsors-heading {
    margin: 0 -1em 1.5em;
}

.event-sponsors .sponsor {
    padding-bottom: 1.5em;
}

.event-info-box {
    font-size: 0.9em;
    position: relative;
}

.event-info-box.current-entry:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: -1px;
    bottom: 0px;
    border-left: 8px solid;
    border-color: inherit;
    z-index: 4;
    opacity: 0.6;
}

.event-info-box-inner {
    padding: 1em 1.2em;
    border-bottom: 1px solid #ccc;
}

.event-info-box:last-child .event-info-box-inner {
    border: none;
}

.event-info-box.past .event-info-box-inner {
    opacity: 0.5;
}

.event-info-box p {
    margin: 0;
}

.event-info-box a {
    text-decoration: none !important;
}

.event-info-box .event-special-title {
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.46);
    font-weight: bold;
}

.event-info-box .event-date {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.84);
}

.event-info-box .event-location {
    color: rgba(0, 0, 0, 0.65);
}

.event-info-box .event-freieplaetze {
    margin-left: 0.75em;
    color: rgba(0, 0, 0, 0.5);
}

.event-info-box .event-freieplaetze .free {
    color: #509200;
}

.event-info-box .event-freieplaetze .none {
    color: #981C20;
}


/* --- Landingpage mit Teaser --- */

.landingpage-teasers {
    margin-top: 2em;
    padding-top: 2em;
    border-top: 2px solid #eee;
}


/* --- Zeitschriften-Übersicht --- */

.zeitschrift-page a {
    color: #754A82;
}

.zeitschrift-page a:hover {
    color: #552565;
}

#about-magazine {
    font-size: 0.9em;
    color: #777;
    padding-top: 1.5em;
}

.issue-cover img {
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.current-issue-box {
    padding: 2em;
    color: #666;
    border: 2px solid #B9A3BF;
}

.current-issue-box .btn {
    text-transform: none !important;
}

.current-issue-title {
    font-weight: bold;
    color: #754A82;
    font-size: 1.6em;
    margin: 0 0 1em;
    font-family: "Roboto", sans-serif;
}

.current-issue-title span {
    font-size: 0.8em;
    text-transform: uppercase;
    display: block;
    margin-bottom: 0.5em;
    opacity: 0.7;
    font-weight: 500;
}

.current-issue-box .issue-cover {
    padding-bottom: 1.5em;
}

.current-issue-download-link {
    text-transform: none;
    margin-bottom: 1em !important;
}

.past-issue {
    color: #666;
    padding-bottom: 3em;
    margin-top: 3em;
    overflow: hidden;
}

.past-issue .title {
    margin: 0 0 1em;
    font-size: 1.4em;
}


/* - Zeitschriften Single - */

.leseprobe-download-button {
    margin-top: 2em;
    margin-bottom: 4em;
}


/* --- Modul: Arbeitsgruppen --- */

.ag-header {
    margin: 0 0 1.5em;
    display: block;
    position: relative;
    border-bottom: 2px solid #888;
    position: relative !important;
}

.ag-logo img {
    max-height: 80px;
}

.ag-title {
    font-size: 1.3em;
    font-family: "Roboto", sans-serif;
}

.ag-nav-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 0;
}

.nav-tabs-ag {
    margin: 0;
    border: none;
    text-align: right;
}

.nav-tabs-ag li {
    display: inline-block;
    float: none;
}

.ag-ap-heading {
    font-family: "Roboto", sans-serif;
    margin: 2.5em 0 2em;
    font-weight: 400;
}

.ag-ap-heading .title {
    display: block;
}

.ag-ap-heading .subtitle {
    display: block;
    font-size: 0.85em;
    color: #999;
}


/* -- Weiterbildungsstätten -- */

.acf-map-marker-title,
.acf-map-marker-address,
.acf-map-marker-description {
    max-width: 200px;
}

.acf-map-marker-description {
    line-height: 1.3em;
    margin-bottom: 2em;
    color: #777;
    font-size: 1.1em;
}

.acf-map-marker-title {
    font-size: 1.25em;
    font-weight: bold;
}

.acf-map-marker-address {
    line-height: 1.3em;
    padding: 0 0.75em;
    margin-bottom: 2em;
}

.acf-map-marker-link {
    text-align: center;
}

.locations {
    padding-top: 2em;
}

#locations-for-acf-map {
    display: none;
}

.location-box {
    padding: 1em;
    margin-bottom: 2em;
    border: 2px solid #ddd;
}

.location-title {
    font-size: 1em;
    margin: -1em -1em 1em;
    padding: 0.8em;
    text-align: center;
    background: #ddd;
    font-family: "Roboto", sans-serif;
}

.location-description {
    margin: 0 -1em 1em;
    padding: 0.5em 1.5em;
    color: #666;
    border-bottom: 2px solid #ddd;
}

.location-info-box {
    font-size: 0.9em;
    line-height: 1.4em;
    position: relative;
    padding-left: 36px;
    margin: 1.5em 0 1.5em;
    color: #666;
}

.location-info-box:after {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -0.35em;
    font-family: "FontAwesome";
    content: "";
    font-size: 1.4em;
    color: #bbb;
}

.location-address.location-info-box:after {
    content: "\f015";
}

.location-tel.location-info-box:after {
    content: "\f095";
}

.location-fax.location-info-box:after {
    content: "\f1ac";
}

.location-contact.location-info-box:after {
    content: "\f007";
}

.location-web.location-info-box:after {
    content: "\f0ac";
}


/* --- Modul: Ansprechparnter --- */

.ap-box-wrapper {
    padding-bottom: 2em;
}

.ap-box-wrapper.lone {
    margin: auto !important;
    float: none !important;
}

.ap-box {
    border: 2px solid #aaa;
}

.ap-header {
    padding: 1em;
    background: #619F00;
    color: #fff;
    margin: -2px -2px 0;
    text-align: center;
}

.ap-title {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 1.3em;
}

.ap-position {
    margin: 0;
    text-transform: uppercase;
    font-size: 0.85em;
    opacity: 0.6;
    padding: 0.5em;
    text-align: center;
    background: #ddd;
}

.ap-location {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 300;
    margin: 0;
}

.ap-info {
    padding: 1em;
}

.ap-info-box {
    position: relative;
    padding-left: 44px;
    color: #666;
    font-size: 0.9em;
    margin-bottom: 1.5em;
}

.ap-info .ap-info-box:last-child {
    margin: 0;
}

.ap-info-box:before {
    color: #999;
    content: "";
    font-family: "FontAwesome";
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    text-align: center;
    margin-top: -0.7em;
    font-size: 18px;
}

.ap-info-box.address:before {
    content: "\f041";
}

.ap-info-box.email:before {
    content: "\f0e0";
}

.ap-info-box.tel:before {
    content: "\f095";
}

.ap-info-box.fax:before {
    content: "\f1ac";
}

.ap-info-box.web:before {
    content: "\f0ac";
}

.ap-info-box.einrichtung:before {
    content: "\f19c";
}


/* --- Modul: Suche --- */

.no-results-info {
    color: #999;
    font-style: italic;
    font-weight: 300;
}


/* --- Password Protection --- */

.password-form {
    max-width: 500px;
    margin: auto;
    text-align: center;
}


/* --- KOKpedia --- */

#kokpedia-header {
    width: 100%;
    margin-top: 50px;
    min-height: 200px;
    position: relative;
}

#kokpedia-header:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: -60px;
    left: -80px;
    height: 250px;
    width: 250px;
    display: block;
    background: url("img/kokpedia-a.svg") top left no-repeat;
    background-size: contain;
    line-height: 1em;
    opacity: 0.2;
}

#kokpedia-title {
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin-bottom: 25px;
}

#kokpedia-header .logo {
    display: block;
    max-width: 350px;
    margin-right: 25px;
    float: left;
}

#kokpedia-title .logo img {
    max-width: 100%;
}

#kokpedia-header .zusatz {
    display: block;
    width: auto;
    padding-top: 33px;
    font-size: 2.5em;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.2em;
    color: #999;
    letter-spacing: 1px;
}

.kokpedia-top-button-wrapper {
    text-align: right;
    padding-top: 35px;
}

#kokpedia-header .topbox {
    color: #fff;
    background: #7ac045;
    position: relative;
    z-index: 2;
}

#kokpedia-header .topbox.alt {
    background: #fff;
    color: #444;
    border: 2px solid #7ac045;
}

#kokpedia-header .topbox p:last-child {
    margin: 0;
}

#kokpedia-header .topbox-vcentered-wrapper {
    height: 100%;
    display: table;
    width: 100%;
}

#kokpedia-header .topbox-vcentered {
    height: 100%;
    width: 100%;
    padding: 1.75em;
    display: table-cell;
    vertical-align: middle !important;
}

#kokpedia-header #topbox-name {
    font-size: 1.3em;
    text-align: center;
}


/* Kokpedia Artikel Single View */

.kokpedia-article-header {
    margin-top: 2em;
}

.kokpedia-article-title {
    font-family: "Roboto", sans-serif;
    color: #7ac045;
    font-weight: 500;
    margin: 2em 0 1em;
    font-size: 2em;
}

.kokpedia-article-title a {
    margin-top: -0.3em;
}

.kokpedia-article-copyright {
    padding-left: 4em;
    color: #888;
    font-size: 0.8em;
    line-height: 1.35em;
    position: relative;
}

.kokpedia-article-copyright:before {
    position: absolute;
    left: 0.75em;
    top: 50%;
    margin-top: -0.5em;
    font-size: 1.5em;
    content: "\00a9";
    color: #bbb;
}

.kokpedia-table {
    border-top: 30px solid #7ac045;
}

.kokpedia-table td.kokpedia-table-element,
.kokpedia-table th.kokpedia-table-element {
    border: none !important;
}

.kokpedia-table td.kokpedia-table-element p:last-child,
.kokpedia-table th.kokpedia-table-element p:last-child {
    margin-bottom: 0;
}

.kokpedia-table th.kokpedia-table-element {
    width: 270px !important;
    color: #433743;
    background: #B9A5B8;
    padding: 1em !important;
}

.kokpedia-table tr.kokpedia-table-element:nth-child(even) th.kokpedia-table-element {
    background: #AA96A9;
}

.kokpedia-table td.kokpedia-table-element {
    padding: 1em !important;
    background: #d7d7d7;
    color: #555;
}

.kokpedia-table tr.kokpedia-table-element:nth-child(odd) td.kokpedia-table-element {
    background: #e7e7e7;
}


/* Tables in the Kokpedia Table */

.kokpedia-table table:not(.table-nonstandard),
.kokpedia-table table:not(.table-nonstandard) td,
.kokpedia-table table:not(.table-nonstandard) tr {
    border-color: #c3c3c3;
}

.kokpedia-table table:not(.table-nonstandard) thead tr {
    background: #c3c3c3;
}


/* Kokpedia Inhaltsverzeichnis */

.kokpedia-list-title {
    text-transform: uppercase;
    border-bottom: 2px solid #e3e3e3;
    padding: 0.4em;
    margin: 0;
    font-size: 1.4em;
}

.kokpedia-archive-list li > a:first-child {
    display: block;
    padding: 0.7em;
    border-bottom: 1px solid #e7e7e7;
}

.kokpedia-list {
    margin-top: 2em;
}

.kokpedia-search-box {
    position: relative;
}

.kokpedia-search-notice {
    font-size: 0.8em;
    color: #aaa;
    line-height: 1.3em;
    margin: -3em 0 1em;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.kokpedia-search-notice.shown {
    opacity: 1;
}

.kokpedia-search-notice span {
    display: block;
}


/* --- Seite: Weiterbildungsstaetten --- */

.locations-legende {
    font-size: 0.85em;
    color: #999;
    margin-top: 1em;
    margin-bottom: 2em;
}

.locations-legende li {
    margin-top: 0.75em;
}

.locations-legende li > img {
    max-height: 20px;
    opacity: 0.8;
    margin-top: -2px;
    margin-right: 0.35em;
}

.locations-mit-ordnung .location-box {
    border-color: #E57776;
}

.locations-mit-ordnung .location-title {
    background: #E57776;
    color: #fff;
}

.locations-mit-ordnung .location-box a {
    color: #E57776;
}

.locations-ohne-ordnung .location-box {
    border-color: #7CA1E4;
}

.locations-ohne-ordnung .location-title {
    background: #7CA1E4;
    color: #fff;
}

.locations-ohne-ordnung .location-box a {
    color: #7CA1E4;
}


/* --- Page: 404 --- */

.error-page {
    text-align: center;
}

.error-title {
    margin-top: 1em;
    color: #7ac045;
    font-size: 3em;
    line-height: 1.2em;
    font-weight: bold;
}

.error-title span {
    display: block;
    color: #aaa;
    font-size: 0.75em;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}


/* --- Page: Blog -- */

.post-date {
    margin-top: 1.5em;
    padding: 1em 0;
    font-size: 0.85em;
    color: #aaa;
    border-top: 1px solid #eee;
}

.post-date i {
    margin-right: 0.5em;
}


/* ---------- RESPONSIVE ---------- */

@media (max-width: 1199px) {
    /* Header Search */
    /* Mobile Menu */
    .subpages-wrapper {
        display: none;
    }
    #kok-mainmenu {
        width: auto;
    }
    .nav-title {
        color: #fff;
        margin: 0;
        padding: 1em;
        display: block;
        text-transform: uppercase;
        background: #7ac045;
        cursor: pointer;
        font-weight: 500;
        position: relative;
    }
    .nav-title:after {
        content: "\f0d8";
        font-family: "FontAwesome";
        position: absolute;
        top: 50%;
        margin-top: -0.65em;
        right: 1em;
    }
    .menu-hidden .nav-title:after {
        content: "\f0d7";
        margin-top: -0.6em;
    }
    .jvs-menu {
        width: auto;
        border: 2px solid #7ac045;
        border-top: none;
    }
    .jvs-menu li {
        display: block !important;
        width: 100%;
        float: none;
        font-size: 1.1em;
        background: #fff !important;
    }
    .jvs-menu li li {
        font-size: 0.9em;
    }
    .jvs-menu li li a {
        padding-left: 2em;
    }
    .jvs-menu li a {
        padding: 1em;
        border-bottom: 2px solid #CCE2B4;
    }
    .jvs-menu > li:last-child a {
        border-bottom: none;
    }
    .jvs-menu li a:after {
        display: none;
    }
    .jvs-menu > li.current_page_item a,
    .jvs-menu:not(.showing-other-subpages) > li.current_page_item a,
    .jvs-menu > li.current_page_ancestor >a,
    .jvs-menu:not(.showing-other-subpages) > li.current_page_ancestor a {
        background: #fff !important;
        color: inherit !important;
    }
    .jvs-menu > li.current_page_item > a,
    .jvs-menu:not(.showing-other-subpages) li.current_page_item > a {
        background: #D9EDC4 !important;
    }
    .jvs-menu > li:not(.current_page_item) > a:hover,
    .jvs-menu:not(.showing-other-subpages) li:not(.current_page_item) > a:hover {
        background: #F6FDF0 !important;
    }
    .jvs-menu li a:hover {}
    .jvs-menu > li .subpages {
        display: block;
    }
    .jvs-menu li li a {
        display: block;
        padding-left: 2em !important;
    }
}

@media (max-width: 992px) {
    .table-wrapper {
        width: 100%;
        overflow-x: scroll;
    }
    #logo {
        max-width: 240px;
    }
    /* Sidebar */
    #sidebar-inner {
        margin-top: 5em;
        padding-left: 0;
        padding-top: 5em;
        border-top: 2px solid #ddd;
    }
    /* Teaser */
    .teaser img {
        width: 100%;
    }
    .teaser.big {
        text-align: left !important;
    }
    .teaser.big .teaser-info {
        padding-top: 2.5em;
        padding-left: 2.5em;
    }
    .teaser.big .teaser-pic img {
        max-width: 110px !important;
    }
    /* Downloads */
    .download-title {
        margin-top: 0 !important;
    }
    /* Slider */
    .slider-bottom-bar {
        position: static;
        background: #E8EBE5;
        color: #3A4C28 !important;
    }
    .slider-bottom-bar .title {
        color: #39561B !important;
    }
    .slider-bottom-bar .subtitle {
        opacity: 0.65;
    }
    .slider-bottom-bar .button-companion {
        color: #467A30 !important;
    }
    /* Kalender */
    .event-box {}
    .event-box .event-date-box,
    .event-box .event-link {
        width: 100%;
        display: block;
        float: none;
    }
    .event-box .event-date-box {
        border-color: #ccc !important;
        border-bottom-width: 0;
    }
    .event-box:hover .event-date-box {
        border-color: #aaa !important;
    }
    .event-box .event-date {
        display: none;
    }
    .event-box .event-mobile-date {
        font-size: 0.9em;
        color: #888;
        display: block;
    }
    .event-box .event-description {
        border-top: none;
        border-left-width: 2px;
    }
    .event-box .event-link {
        display: none;
    }
    .dates-list li {
        display: block;
    }
    /* Event Single */
    .event-description {
        padding-top: 3.5em;
        border-top: 2px solid #ddd;
    }
    /* Pull Right Fix */
    .pull-right {
        float: none !important;
    }
    /* -- Zeitschriften -- */
    .issue-cover {
        text-align: center;
    }
    #about-magazine-inner {
        padding: 2em;
        margin-bottom: 2em;
        border: 2px solid #ddd;
    }
    #about-magazine-logo {
        margin-bottom: 1em;
        margin-left: 1em;
        max-width: 250px;
        float: right;
    }
    /* KOKpedia */
    #kokpedia-header {
        text-align: center;
    }
    #kokpedia-header .logo {
        float: none;
        margin: auto;
    }
    #kokpedia-header .zusatz {
        padding-top: 0px;
    }
    #kokpedia-header:before {
        left: 50%;
        margin-left: -125px;
        opacity: 0.1;
    }
    .kokpedia-top-button-wrapper {
        text-align: center;
        padding-bottom: 40px;
    }
}

@media (max-width: 768px) {
    /* Tabs */
    .ag-logo {
        text-align: center;
    }
    .nav-tabs-ag,
    .ag-nav-wrapper {
        position: static;
        text-align: center;
    }
    .nav-tabs-ag li {
        display: block;
        float: none;
    }
    .nav-tabs-ag li a {
        border-bottom: 2px solid #eee !important;
    }
    .nav-tabs-ag li:last-child a {
        border: none !important;
    }
    /* Image Gallery */
    .gallery li {
        width: 33%;
        padding-bottom: 33%;
    }
    /* Blog Box */
    .blog-box.with-icon {
        padding: 1em !important;
    }
    .blog-box.with-icon .icon {
        position: static;
        margin: 0 0 1em;
    }
    .blog-box .pic,
    .blog-box .pic-inner,
    .blog-box-content {
        float: none;
        width: auto !important;
        display: block;
    }
    .blog-box .pic {
        margin-bottom: 1.5em;
        text-align: center;
    }
    /* Top Bar */
    #topbar-inner {
        overflow: hidden;
    }
    #topbar p {
        display: block;
        padding: 0.4em;
        font-size: 0.9em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.04) !important;
    }
    /* Downloads */
    .download-container .download-title {
        text-align: left;
        padding-bottom: 1em;
        text-align: center;
    }
    /* -- Zeitschriften -- */
    .past-issue {
        overflow: hidden;
    }
    .issue-cover {
        float: left;
        max-width: 150px;
    }
    .issue-text {
        overflow: hidden;
        width: auto;
    }
}

@media (max-width:600px) {
    /* Sidebar */
    .sidebar-teaser-big-icon {
        display: none;
    }
    .sidebar-teaser .teaser-info {
        padding: 0 1em !important;
        width: 100% !important;
    }
    .sidebar-teaser .teaser-title {
        margin-top: 0 !important;
    }
}

@media (max-width: 550px) {
    /* Image Gallery */
    .gallery li {
        width: 50%;
        padding-bottom: 50%;
    }
    .teaser.big .teaser-pic {
        display: none;
    }
    .teaser.big .teaser-info,
    .teaser.big .teaser-title {
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    .teaser.big .teaser-info {
        padding: 2em 0;
    }
}

@media (max-width: 460px) {
    /* -- Zeitschriften -- */
    .issue-cover {
        width: 100%;
        max-width: 100%;
        float: none;
        margin-bottom: 25px;
        text-align: center;
    }
    .issue-cover img {
        max-width: 140px;
    }
}

@media print {
    .search-wrapper-wrapper,
    #kok-mainmenu,
    .share-buttons {
        display: none;
    }
    a[href]:after {
        content: none
    }
}
