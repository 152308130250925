
.owl-carousel p { margin: 0; }

/* - Slides - */
.owl-carousel .item {
	height: 400px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	font-family: "Roboto Slab", sans-serif;
	box-sizing: border-box;
	padding: 30px;
}

.owl-carousel .item-contents-wrapper {
	width: 100%;
	height: 100%;
	display: table;
}
	.owl-carousel .item-contents-inner {
		display: table-cell;
		height: 100%;
		vertical-align: middle;
		position: relative;
	}

	.link.only-pic {
		display: block;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

.owl-carousel .item-title {
	display: inline-block;
	padding: 14px 20px;
	background: rgba(255,255,255,0.3);
	font-size: 1.6em;
	margin-bottom: 8px;
	max-width: 60%;
	line-height:1.2em;
}


.owl-carousel .item-text {
	max-width: 60%;
	text-align: left;
	font-family: "Roboto",sans-serif;
}
.owl-carousel .item-text-inner {
	margin-bottom: 12px;
	font-size: 1.1em;
	display: inline-block;
	padding: 20px;
	background: rgba(255,255,255,0.3);
}

/* Rechts angeordneter text */
.owl-carousel .item.right { text-align: right; }
.owl-carousel .item.right .item-text { float: right; }


	/* white Text and background */
	.owl-carousel .item.white-white  .item-title, 
	.owl-carousel .item.white-white .item-text-inner {
		background: rgba(255,255,255,0.25);
		color: #fff;
	}

	/* white Text and black background */
	.owl-carousel .item.white-black .item-title, 
	.owl-carousel .item.white-black  .item-text-inner {
		background: rgba(0,0,0,0.5);
		color: #fff;
	}

	/* white Text and transparent background */
	.owl-carousel .item.white-transparent .item-title, 
	.owl-carousel .item.white-transparent  .item-text-inner {
		background: none;
		color: #fff;
		padding: 0;
	}

	/* black Text and background */
	.owl-carousel .item.black-black  .item-title, 
	.owl-carousel .item.black-black .item-text-inner {
		background: rgba(0,0,0,0.5);
		color: rgba(0,0,0,0.7);
	}
	.owl-carousel .item.black-black .item-title { color: #000; }

	/* black Text and white background */
	.owl-carousel .item.black-white .item-title, 
	.owl-carousel .item.black-white  .item-text-inner {
		background: rgba(255,255,255,0.6);
		color: rgba(0,0,0,0.7);
	}
	.owl-carousel .item.black-white .item-title { color: #000; }

	/* white Text and transparent background */
	.owl-carousel .item.black-transparent .item-title, 
	.owl-carousel .item.black-transparent  .item-text-inner {
		background: none;
		color: rgba(0,0,0,0.8);
		padding: 0;
	}


	/* Button */
	.slide-button-wrapper {
		clear: both;
		padding-top: 1em;
		font-family: "Roboto",sans-serif;
	}

/* - Controls - */
.owl-carousel { overflow: hidden;}
.owl-theme .owl-controls {
	margin-top: 0;
}
	.owl-prev, .owl-next {
		position: absolute;
		top: 50%;
		margin-top: -20px;
		content: "\f104";
		color: #000;
		background: #fff;
		opacity: 0;
		font-family: "FontAwesome";
		height: 40px;
		width: 40px;
		font-size: 24px;
		border-radius: 99em;
		text-align: center;
		z-index: 10;
		line-height: 38px;
		padding-right: 2px;
		box-sizing: border-box;
		transition: all 0.16s ease-in-out;
	}
		.owl-carousel:hover .owl-prev, .owl-carousel:hover .owl-next {
			opacity: 0.4;
		}
		.owl-carousel:hover .owl-prev:hover, .owl-carousel:hover .owl-next:hover { opacity: 1; }
	
	.owl-prev { left: -70px; }
	.owl-carousel:hover .owl-prev { left: 12px; }
	
	.owl-next {
		padding-right: 0;
		padding-left: 3px;
		right: -70px;
	}
	.owl-carousel:hover .owl-next { right: 12px; }







@media (max-width: 991px) {
	.owl-carousel .item.right { text-align: left; }

	.owl-carousel .item-title,
	.owl-carousel .item-text {
		max-width: 100%;
		float: none;
	}

	.owl-carousel .slide-button-wrapper { text-align: center; }

	.owl-prev, .owl-next { opacity: 0.4; }
	.owl-prev { left:12px !important; }
	.owl-next  { right:12px !important; }
}