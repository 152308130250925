$font-path: "../fonts" !default;


@font-face {
	font-family: "Roboto Slab";
	font-style: normal;
	font-weight: 100;
	src: url("#{$path-fonts}/Roboto_Slab/RobotoSlab-Thin.ttf");
}


@font-face {
	font-family: "Roboto Slab";
	font-style: normal;
	font-weight: 300;
	src: url("#{$path-fonts}/Roboto_Slab/RobotoSlab-Light.ttf");
}


@font-face {
	font-family: "Roboto Slab";
	font-style: normal;
	font-weight: 400;
	src: url("#{$path-fonts}/Roboto_Slab/RobotoSlab-Regular.ttf");
}


@font-face {
	font-family: "Roboto Slab";
	font-style: normal;
	font-weight: 700;
	src: url("#{$path-fonts}/Roboto_Slab/RobotoSlab-Bold.ttf");
}
