$path-fonts: '../fonts' !default;


@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 100;
	src: url("#{$path-fonts}/Roboto/Roboto-Thin.ttf");
}

@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 100;
	src: url("#{$path-fonts}/Roboto/Roboto-ThinItalic.ttf");
}


@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 300;
	src: url("#{$path-fonts}/Roboto/Roboto-Light.ttf");
}

@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 300;
	src: url("#{$path-fonts}/Roboto/Roboto-LightItalic.ttf");
}


@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	src: url("#{$path-fonts}/Roboto/Roboto-Regular.ttf");
}

@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 400;
	src: url("#{$path-fonts}/Roboto/Roboto-Italic.ttf");
}


@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	src: url("#{$path-fonts}/Roboto/Roboto-Medium.ttf");
}

@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 500;
	src: url("#{$path-fonts}/Roboto/Roboto-MediumItalic.ttf");
}


@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	src: url("#{$path-fonts}/Roboto/Roboto-Bold.ttf");
}

@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 700;
	src: url("#{$path-fonts}/Roboto/Roboto-BoldItalic.ttf");
}


@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 800;
	src: url("#{$path-fonts}/Roboto/Roboto-Black.ttf");
}

@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 800;
	src: url("#{$path-fonts}/Roboto/Roboto-BlackItalic.ttf");
}
